<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="CommandIcon" size="19"/>
          <h4 class="mb-0 ml-50">Komite Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="İsim" rules="required">
              <b-form-group label="İsim">
                <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea v-model="dataInfo.description" rows="3" placeholder="Açıklama..." :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Durum" rules="required">
              <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex justify-content-between align-items-center border-bottom border-top pt-1 pr-1 pb-1">
          <div class="d-flex">
            <feather-icon icon="UsersIcon" size="19"/>
            <h4 class="mb-0 mt-0 ml-50">Komite Kullanıcıları</h4>
          </div>
          <b-button variant="primary" @click="newUserModal" size="md">
            <feather-icon icon="PlusSquareIcon" size="16"/>
            <span class="align-middle" role="button"> Ekle</span>
          </b-button>
        </div>

        <div v-for="(user, index) in dataInfo.users" :key="index" class="d-flex justify-content-between align-items-center border-bottom py-1" v-if="!user.isDeleted">
          <div class="d-flex justify-content-between align-items-center">
            <b-avatar size="32" class="mr-50" variant="light-primary" :src="getApiFile(user.userProfilePicture)" :text="avatarText(user.userFullName)"/>
            <div class="user-page-info">
              <h6 class="mb-0">
                {{ user.userFullName }}
              </h6>
              <small class="text-muted">{{ user.userPositionName }}</small>
            </div>
          </div>
          <div>
            <b-button variant="warning" class="btn-icon" @click="editUser(user)">
              <feather-icon icon="EditIcon"/>
              Düzenle
            </b-button>
            <b-button variant="danger" class="btn-icon ml-1" @click="removeUser(user)">
              <feather-icon icon="XIcon"/>
              Çıkar
            </b-button>
          </div>
        </div>

        <b-modal v-model="modalShow" title="Kullanıcı Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" no-close-on-esc no-close-on-backdrop @ok="handleModal">
          <form ref="form" @submit.stop.prevent="submitModal">

            <b-form-group label="Kullanıcı">
              <v-select v-model="userInfo.userId" :clearable="false" :options="userOptions" label="label" :reduce="val => val.value">
                <template #option="{ avatar, label }">
                  <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                  <span class="ml-50 align-middle"> {{ label }}</span>
                </template>
                <template #selected-option="{ avatar, label }">
                  <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                  <span class="ml-50 align-middle"> {{ label }}</span>
                </template>
              </v-select>
            </b-form-group>

          </form>
        </b-modal>

        <action-buttons :back-route="'definition-committee-list'"/>

      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {BAvatar, BBadge, BButton, BButtonGroup, BButtonToolbar, BCard, BCardText, BCol, BDropdown, BDropdownItem, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BFormTimepicker, BListGroup, BListGroupItem, BMedia, BMediaAside, BMediaBody, BModal, BRow, BTable, BTooltip} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {email, required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/committee/store"
import userModule from "@/views/organization/employee/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import flatpickr from "vue-flatpickr-component/src/component"
import {BooleanColumnTable, DateColumnTable, IdColumnTable, RemoveButtonTable} from "@/components/Table";
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue";
import Vue from "vue";

export default {
  components: {
    BooleanColumnTable,
    DateColumnTable, IdColumnTable, DateTimeColumnTable, RemoveButtonTable,
    BTooltip, BDropdownItem, BButtonGroup, BDropdown, BButtonToolbar,
    BFormTimepicker,
    BBadge,
    BFormFile,
    BCardText,
    BMediaBody,
    BMediaAside,
    BMedia,
    BListGroupItem,
    BListGroup,
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    flatpickr,
    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: null,
      name: '',
      description: '',
      isActive: true,
      users: [],
    })

    const userInfo = ref({
      id: 0,
      userId: null,
      userProfilePicture: null,
      userFullName: '',
      userPositionName: '',
      index: null,
      isDeleted: false
    })

    const userOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems', {sortColumn: 'firstName', sortDesc: false, isActive: true}).then(response => {
      response.data.data.forEach((value, index) => {
        if (!userOptions.value.some(e => e.value === value.id)) {
          userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture, positionName: value.positionName, firstName: value.firstName, lastName: value.lastName})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {

      if (dataInfo.value.users.filter(item => item.isDeleted === false).length === 0) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Kullanıcı bilgileri boş geçilemez!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'definition-committee-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const userEditInfo = ref(null)
    const modalShow = ref(false)

    const newUserModal = () => {
      userInfo.value = {
        id: 0,
        userId: null,
        userProfilePicture: null,
        userFullName: '',
        userPositionName: '',
        index: null,
        isDeleted: false
      }

      modalShow.value = true
    }

    const submitModal = () => {
      let isAppendItem = true

      dataInfo.value.users.forEach((value, index) => {
        if (value === userEditInfo.value) {
          isAppendItem = false
          value.id = userInfo.value.id
          value.userId = userInfo.value.userId
          value.userProfilePicture = userOptions.value.filter(item => item.value === userInfo.value.userId)[0].avatar
          value.userFullName = userOptions.value.filter(item => item.value === userInfo.value.userId)[0].label
          value.userPositionName = userOptions.value.filter(item => item.value === userInfo.value.userId)[0].positionName
          value.isDeleted = userInfo.value.isDeleted
          value.index = Math.floor(Math.random() * 112114115171)
        }
      });

      if (isAppendItem) {
        dataInfo.value.users.push({
          id: userInfo.value.id,
          userId: userInfo.value.userId,
          userProfilePicture: userOptions.value.filter(item => item.value === userInfo.value.userId)[0].avatar,
          userFullName: userOptions.value.filter(item => item.value === userInfo.value.userId)[0].label,
          userPositionName: userOptions.value.filter(item => item.value === userInfo.value.userId)[0].positionName,
          isDeleted: userInfo.value.isDeleted,
          index: Math.floor(Math.random() * 112114115171)
        })
      }

      userInfo.value = {
        id: 0,
        userId: null,
        userProfilePicture: null,
        userFullName: '',
        userPositionName: '',
        index: null,
        isDeleted: false
      }

      userEditInfo.value = null
      modalShow.value = false
    }

    const handleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()

      if (userInfo.value.userId === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Kullanıcı seçimi zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitModal()
    }

    const removeUser = (userData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.users.forEach((value, index) => {
            if (value === userData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const editUser = (userData) => {
      userEditInfo.value = userData
      userInfo.value = {
        id: userData.id,
        userId: userData.userId,
        userProfilePicture: userData.userProfilePicture,
        userFullName: userData.userFullName,
        userPositionName: userData.userPositionName,
        isDeleted: userData.isDeleted,
      };

      modalShow.value = true
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'definition-committee-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    return {
      busy,
      dataInfo,
      userInfo,
      refFormObserver,
      userOptions,
      modalShow,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      newUserModal,
      submitModal,
      handleModal,
      removeUser,
      editUser,
      avatarText,
      getApiFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker';
</style>
